import { lazy } from 'react';
import Main from '../../components/layout/Main';
import ContextProviderUserManager from '../../pages/user-manager/context/contextprovider.usermanager';

export const userManagerRoutes = [
  {
    path: '/user-management/role',
    exact: true,
    page: ['user management', 'role'],
    component: lazy(() => import('../../pages/user-manager/role')),
    layout: Main,
    role: ['Superadmin', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/role/create',
    exact: true,
    page: ['user management', 'role', 'create'],
    component: lazy(() => import('../../pages/user-manager/create/create.role')),
    layout: Main,
    role: ['Superadmin', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/role/edit/:id',
    exact: true,
    page: ['user management', 'role', 'edit'],
    component: lazy(() => import('../../pages/user-manager/edit/edit.role')),
    layout: Main,
    role: ['Superadmin', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/user',
    exact: true,
    page: ['user management', 'user'],
    component: lazy(() => import('../../pages/user-manager/user')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/user/create',
    exact: true,
    page: ['user management', 'user', 'create'],
    component: lazy(() => import('../../pages/user-manager/create/create.user')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/user/edit/:id',
    exact: true,
    page: ['user management', 'user', 'edit'],
    component: lazy(() => import('../../pages/user-manager/edit/edit.user')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/customer',
    exact: true,
    page: ['user management', 'customer'],
    component: lazy(() => import('../../pages/user-manager/customer')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/customer/create',
    exact: true,
    page: ['user management', 'customer', 'customer'],
    component: lazy(() => import('../../pages/user-manager/create/create.customer')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/customer/edit/:id',
    exact: true,
    page: ['user management', 'customer', 'edit'],
    component: lazy(() => import('../../pages/user-manager/edit/edit.customer')),
    layout: Main,
    role: ['Superuser'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/karyawan',
    exact: true,
    page: ['user management', 'karyawan'],
    component: lazy(() => import('../../pages/user-manager/karyawan')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/karyawan/create',
    exact: true,
    page: ['user management', 'karyawan', 'create'],
    component: lazy(() => import('../../pages/user-manager/create/create.karyawan')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/karyawan/edit/:id',
    exact: true,
    page: ['user management', 'karyawan', 'edit'],
    component: lazy(() => import('../../pages/user-manager/edit/edit.karyawan')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
  {
    path: '/user-management/karyawan/import',
    exact: true,
    page: ['user management', 'karyawan', 'import'],
    component: lazy(() => import('../../pages/user-manager/import/import.karyawan')),
    layout: Main,
    role: ['Superuser', 'Owner'],
    title: 'User Management',
    context: ContextProviderUserManager,
  },
];
