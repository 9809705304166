import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification/CustomNotification';
import { SERVICE_MARKETING } from '../../config/keys';
import httpService from '../../services/http.service';

export const getlistCampaign = async (params: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/campaign-blast`, { params });

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const getDetailCampaign = async (id: any) => {
  try {
    const res = await httpService.get(`${SERVICE_MARKETING}/campaign-blast/${id}`);

    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const createCampaign = async (data: any) => {
  try {
    const res = await httpService.post(`${SERVICE_MARKETING}/campaign-blast`, data);
    // SuccessNotification({ description: 'Campaign berhasil dibuat' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const updateCampaign = async (id: string, data: any) => {
  try {
    const res = await httpService.put(`${SERVICE_MARKETING}/campaign-blast/${id}`, data);
    // SuccessNotification({ description: 'Campaign berhasil diperbaruhi' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};

export const deleteCampaign = async (id: any) => {
  try {
    const res = await httpService.delete(`${SERVICE_MARKETING}/campaign-blast/${id}`);
    SuccessNotification({ description: 'Campaign Blast berhasil dihapus' });
    return res;
  } catch (error: any) {
    return ErrorNotification(error);
  }
};
