import moment from 'moment';

export const optionTypeCampaign = [
  { value: 'notif', label: 'Push Notifikasi' },
  { value: 'email', label: 'Email' },
  { value: 'wa', label: 'Whatsapp' },
];

const stringifyDescription = (text: any) => JSON.stringify(text);

export const createPayloadCampaign = (values: any) => {
  const formData = new FormData();

  const publishAt = values.publish_at.format('YYYY-MM-DD');
  const publishTime = values.publish_time.format('HH:mm:ss');

  formData.append('title', values.title);
  formData.append('picture_url', values.originFileObj || values.picture_url);
  formData.append('publish_time', publishTime);
  formData.append('publish_today', String(values.publish_today));
  formData.append('publish_at', publishAt);
  formData.append('recipient_files', '-');
  formData.append('publish_type', values.publish_type);
  formData.append(
    'publish_on_notif',
    values.is_push_notif
      ? `{"description":${stringifyDescription(
          values.publish_on_notif.replace(/\s+$/g, ''),
        )},"sub_title":"${values.sub_title_notif}"}`
      : 'null',
  );
  formData.append(
    'publish_on_email',
    values.is_email
      ? `{"description":${stringifyDescription(
          values.publish_on_email.replace(/\s+$/g, ''),
        )},"sub_title":"${values.sub_title_email}"}`
      : 'null',
  );
  formData.append('publish_on_wa', '');

  return formData;
};

export const setDetailCampaign = (values: any) => ({
  ...values,
  publish_on_email: values.publish_on_email
    ? {
        description: values.publish_on_email.description.replace(/\\/g, '"'),
        sub_title: values.publish_on_email?.sub_title || '',
      }
    : null,
  publish_on_notif: values.publish_on_notif
    ? {
        description: values.publish_on_notif.description.replace(/\\/g, '"'),
        sub_title: values.publish_on_notif?.sub_title || '',
      }
    : null,
  publish_time: moment(values.publish_time, 'HH:mm:ss'),
  publish_at: moment(values.publish_at),
});
